.drawer-link {
    text-decoration: none;
    color: white;
  }
  
  .drawer-link:hover {
    text-decoration: none;
    color: black;
  }
  
  .drawer-link:visited {
    text-decoration: none;
    color: white;
  }
  
  .drawer-link:active {
    text-decoration: none;
    color: black;
  }
  
  .drawer-button {
    text-decoration: none;
    color: black;
  }
  
  .drawer-button:hover {
    text-decoration: none;
    color: black;
  }
  
  .drawer-button:visited {
    text-decoration: none;
    color: black;
  }
  
  .drawer-button:active {
    text-decoration: none;
    color: black;
  }
  
  /* Layout.css */

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height for the layout */
  
}


