/* Ensure HTML and body take full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* Root div takes full height and uses flex */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
}
