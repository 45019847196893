.eventpage {
    font-family: 'Arial', sans-serif;
    color: #fff;
  }
  
  .event-background {
    background-image: url('../images/Group1.png'); /* Update with your image path */
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .event-background-2 {
    background-image: url('../images/Group3.png'); /* Update with your image path */
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .eventpageoverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease;
    z-index: 0;
  }
  
  .content {
    text-align: center;
    transform: translateY(20px);
    opacity: 0;
    animation: fadeInUp 1s forwards;
    z-index: 3;
  }
  
  @keyframes fadeInUp {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .event-section {
    padding: 60px 20px;
    background: #333; /* Section background color */
    transition: background-color 0.5s ease;
  }
  
  .event-section:nth-of-type(even) {
    background: #444; /* Alternate background color */
  }